<template>
  <div>
    <van-tabs
      v-model="currentTabName"
      @click="clickTitle"
      color="#c10000"
      sticky
    >
      <van-tab name="2021-07-23" title="07-23"></van-tab>
      <van-tab name="2021-07-24" title="07-24"></van-tab>
      <van-tab name="2021-07-25" title="07-25"></van-tab>
    </van-tabs>

    <div v-if="tableData.length === 0">
      <van-empty description="暂无数据" />
    </div>
    <div class="program-container" v-else>
      <div class="program-room">
        <ul>
          <li v-for="(item, index) in tableData" :key="index">
            <div class="cell">
              {{ item.GroupName }}
            </div>
          </li>
        </ul>
      </div>
      <div class="program-list">
        <ul>
          <li v-for="(item, index) in tableData" :key="index">
            <van-button
              class="customButton"
              type="primary"
              v-for="t in item.Schedules"
              :key="t.ID"
              @click="scheduleDetail(t)"
            >
              <div>
                <div class="date">{{ t.Time }}</div>
                <div class="desc">
                  {{ t.Content | formatContent }}
                </div>
              </div>
            </van-button>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="tableBox">
      <el-table :data="tableData" :show-header="false" style="width: 100%">
        <el-table-column fixed label="组" width="70">
          <template slot-scope="scope">
            <div class="GroupName">{{ scope.row.GroupName }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="meetingBox">
              <div class="meetingContent">
                <van-button
                  class="customButton"
                  type="primary"
                  v-for="t in scope.row.Schedules"
                  :key="t.ID"
                  @click="scheduleDetail(t)"
                >
                  <div>
                    <div class="date">{{ t.Time }}</div>
                    <div class="desc">
                      {{ t.Content | formatContent }}
                    </div>
                  </div>
                </van-button>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div> -->
  </div>
</template>

<script>
import { GetSchedule } from "@/api/index.js";
import { Toast } from "vant";
import Vue from "vue";

Vue.use(Toast);

export default {
  name: "Schedule",
  data() {
    return {
      tableData: [],
      currentTabName: "2021-07-23",
    };
  },
  methods: {
    async GetScheduleAsync() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      const r = await GetSchedule({
        date: this.currentTabName,
      });
      if (r.code === 1) {
        // console.log(r.data);
        this.tableData = r.data;
      } else {
      }
      Toast.clear();
    },
    clickTitle(name, title) {
      // console.log(name, title);
      this.currentTabName = name;
      this.GetScheduleAsync();
    },
    scheduleDetail(t) {
      // console.log(t);
      // return
      // sessionStorage.ScheduleDetail = JSON.stringify(t)
      this.$router.push({
        name: "ScheduleDetail",
        params: {
          scheduleId: t.ID,
        },
      });
    },
  },
  created() {
    this.GetScheduleAsync();
  },
  filters: {
    formatContent(v) {
      return v || "空";
    },
  },
};
</script>

<style scoped>
.program-container {
  display: block;
}
.program-room {
  width: 90px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  float: left;
}
.program-room > ul {
  box-sizing: border-box;
  width: 100%;
}
.program-room li {
  padding: 10px;
  background-color: #f3ead8;
  color: #be7518;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.program-room .cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
}
.program-list {
  margin-left: 90px;
  padding: 10px 0;
  width: calc(100% - 90px);
  overflow-x: scroll;
  overflow-y: hidden;
  display: block;
}
.program-list li {
  height: 100px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.tableBox {
  margin: 10px;
  display: none;
}
.el-table >>> .el-table__body {
  width: 100% !important;
}
.el-table >>> td,
.el-table >>> th {
  padding: 5px 0;
}

.el-table >>> .cell {
  overflow: auto;
}

.el-table >>> .el-table_1_column_1 .cell {
  background-color: #f3ead8;
  color: #be7518;
  height: 100px;
  display: flex;
  align-items: center;
}
.el-table >>> td {
  border-bottom: none;
}
.meetingBox {
  width: 100%;
  /* overflow-x: auto;
  overflow-y: hidden; */
}
.meetingContent {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
.van-button {
  margin-right: 10px;
}
.customButton {
  width: 140px;
  height: 100px;
  padding: 0;
  background: #f3ead8;
  border: 1px solid #fff;
}
.customButton >>> .van-button__text {
  display: block;
  width: 100%;
  height: 100%;
}
.customButton .date {
  background: #e3b965;
  color: #fff;
  font-size: 12px;
  padding: 5px;
}
.customButton .desc {
  white-space: normal;
  background-color: transparent;
  color: #444444;
  /* border-radius: 5px; */
  padding: 5px;
  text-align: left;
  font-size: 12px;
  min-height: 74px;
  line-height: 1.5;
}
</style>